import React from 'react'

export const auphilosopy = {
    heading: 'AU Philosophy',
    description: "AU Group's philosophy revolves around integrity, innovation, and unwavering dedication to clients. We believe in transparent practices, superior service, and fostering a sense of pride in ownership. Our commitment to these values defines our path towards excellence in the real estate industry."
}

export const aubussiness = {
    heading: "Business Verticals",
    bussinessimgmaintop: [
        {
            src: "/websiteimages/bussiness-vertical-img1.jpg",
            alt: "bussiness Vertical",
            url: "/arihant-enclave",
            logoSrc: "/websiteimages/AU-Residences-logo.png",
            logoAlt: "Residences",
            text: "Premium, tailored residences for lifestyle and aspiration-driven families."
        },
        {
            src: "/websiteimages/au-commercial-img.jpg",
            alt: "commercial",
            url: "/family-hub",
            logoSrc: "/websiteimages/AU-Commercial.png",
            logoAlt: "Commercial Logo",
            text: "Dynamic commercial spaces, empowering businesses for success."
        }
    ],
    bussinessimgmainbottom: [
        {
            src: "/websiteimages/au-advisory-img.jpg",
            alt: "advisory",
            url: "/advisory",
            logoSrc: "/websiteimages/AU-Advisory-logo.png",
            logoAlt: "Advisory",
            text: "Expert guidance, unlocking real estate potential, making informed decisions."
        },
        {
            src: "/websiteimages/au-joint-img.jpg",
            alt: "Joint Ventures",
            url: "/joint-ventures",
            logoSrc: "/websiteimages/AU-Joint-Ventures-logo.png",
            logoAlt: "Joint Ventures Logo",
            text: "Strategic collaborations, creating value and innovation together."
        },
        {
            src: "/websiteimages/au-services-img.jpg",
            alt: "Services",
            url: "/service",
            logoSrc: "/websiteimages/AU-Services-logo.png",
            logoAlt: "Services Logo",
            text: "Comprehensive services, enhancing experiences, your all-in-one real estate services partner."
        }
    ]
}

export const uacorporate = {
    heading: "Corporate AV",
    dataVideo: "7ixLP6mUqnw",
    backgroundImg: "/websiteimages/Video-with-text-bg.jpg",
    youTubeVidio: "https://www.youtube.com/embed/efXcEqISaOo?autoplay=1&showinfo=0&controls=0&rel=0"
}

export const aufoundermessage = {
    heading: "Founder's Message",
    text1: "At AU Real Estate Group, our journey is defined by a commitment to excellence. We believe in more than just transactions. We believe in personal connections and in delivering service that transforms properties into generational assets. Our investor-friendly approach is rooted in putting your interests first, and we're dedicated to your growth.",
    text2: "Together, let's build a legacy of trust, value, and customer-centricity in the world of real estate.",
    subHeading: "Warm regards,<br/>Ashish Agarwal",
    founderImg: "/websiteimages/founder-img1.jpg",
    imgAlt: "Founder Message"
}

export const auvalues = {
    heading: "AU's Values",
    accordionImg: [
        {
            img: "/websiteimages/commitment-img.jpg",
            uniqueClass: "map1",
            alt: "commitment",
        },
        {
            img: "/websiteimages/customer-img.jpg",
            uniqueClass: "map2",
            alt: "customer",
        },
        {
            img: "/websiteimages/dependable-img.jpg",
            uniqueClass: "map5",
            alt: "dependable",
        },
        {
            img: "/websiteimages/integity-img.jpg",
            uniqueClass: "map4",
            alt: "integity",
        },
        {
            img: "/websiteimages/problem-img.jpg",
            uniqueClass: "map6",
            alt: "problem",
        },
        {
            img: "/websiteimages/professional-img.jpg",
            uniqueClass: "map7",
            alt: "professional",
        },
        {
            img: "/websiteimages/transparency-img.jpg",
            uniqueClass: "map3",
            alt: "transparency",
        }
    ],
    accordionItem: [
        {
            uniqueHeadingId: "headingOne",
            dataRelatedMap: "map1",
            heading: "Commitment",
            uniqueTabId: "collapseOne",
            text: "Our commitment to delivery means adhering to timelines with unwavering dedication. <br /> Regarding specifications, we ensure every detail is met precisely. <br /> Quality is our uncompromising standard, guaranteeing excellence in every aspect of our work"
        },
        {
            uniqueHeadingId: "headingTwo",
            dataRelatedMap: "map2",
            heading: "Customer Centricity",
            uniqueTabId: "collapseTwo",
            text: "Customer-centricity is the cornerstone of our approach, starting with personalized planning that aligns with clients' goals. <br /> We offer support, transparency, and flexibility, ensuring their ownership experience is smooth, fulfilling, and tailored to their evolving needs."
        },
        {
            uniqueHeadingId: "headingThree",
            dataRelatedMap: "map3",
            heading: "Transparency",
            uniqueTabId: "collapseThree",
            text: "Transparency is our guiding principle in every interaction.<br /> We openly share information, ensuring clarity, honesty, and trust. <br /> Whether in deals or day-to-day interactions, it fosters an environment of integrity, accountability, and strong, lasting partnerships"
        },
        {
            uniqueHeadingId: "headingFour",
            dataRelatedMap: "map4",
            heading: "Integrity",
            uniqueTabId: "collapseFour",
            text: "Integrity is our unwavering commitment to ethical conduct in all interactions. <br /> We uphold honesty, ethics, and moral principles, ensuring that every decision and action is guided by a strong moral compass. <br /> This fosters trust and respect in every engagement."
        },
        {
            uniqueHeadingId: "headingFive",
            dataRelatedMap: "map5",
            heading: "Dependability",
            uniqueTabId: "collapseFive",
            text: "Dependability is our promise to customers. <br /> We consistently deliver on commitments, providing reliability and peace of mind. <br /> Clients can trust us to be there when needed, ensuring their confidence in our services and fostering long-lasting relationships"
        },
        {
            uniqueHeadingId: "headingSix",
            dataRelatedMap: "map6",
            heading: "Problem Solving",
            uniqueTabId: "collapseSix",
            text: "Problem-solving is our proactive approach to customer service. <br /> We embrace challenges, seeking innovative solutions to meet unique needs. <br /> By addressing issues swiftly and effectively, we empower our customers and demonstrate our commitment to their satisfaction and success."
        },
        {
            uniqueHeadingId: "headingSeven",
            dataRelatedMap: "map7",
            heading: "Professionalism",
            uniqueTabId: "collapseSeven",
            text: "Professionalism is our hallmark. <br /> We maintain the highest standards in conduct, communication, and service delivery.<br /> With a focus on expertise, respect, and consistency, we ensure every customer experience reflects our commitment to excellence and integrity"
        },
    ]
}

export const autestimonial = [
    {
        url: "https://www.youtube.com/watch?v=E7R_PZj1CRU",
        text: "Green and open spaces, low-density housing, and the freedom for open housing have given us the best of both worlds. We relish the natural surroundings, the tranquillity of low-density living, and the creative freedom to design our dream home. It's the perfect blend of open living within a comfortable, spacious community.",
        name: "Mr. & Mrs. Maheshwari"
    },
    {
        url: "https://www.youtube.com/watch?v=WibZYuS-25U",
        text: "I'm very happy with my shop at Family Hub. The prime location, strong rental income, and placement between two thriving townships make it a fantastic investment. It's the ideal spot for my business, and I couldn't be happier.",
        name: "Mr. Pramod Shrivastava"
    },
    {
        url: "https://www.youtube.com/watch?v=kXDNNQ5NLZ8",
        text: "We are proud of our choice of Arihant Enclave as our home. The 3-tier security, the freedom to choose between plots and villas, and creating our customized house have made it the perfect place for us to settle down. It's an ideal community to begin this new chapter of our lives.",
        name: "Mr. Ashutosh and Mrs. Pradnya"
    },
    {
        url: "https://www.youtube.com/watch?v=CNSwuA7AayY",
        text: "My experience with AU has been excellent. I started with a shop in Family Hub, which has been a great investment. The transparent and supportive staff made it all very smooth. <br /> Encouraged by this, I invested in a plot at Arihant Enclave. The professionalism and transparency of the AU team were remarkable. Family Hub is spacious, open, and growth-oriented, surpassing my expectations. I'm thrilled with my investments and look forward to more opportunities with AU.",
        name: "Mr. Manoj and Mrs. Manju"
    },
    {
        url: "https://www.youtube.com/watch?v=I7VtlOiIFzQ",
        text: "Arihant offers the comfort of a society, independence of a villa, and the freedom to create our dream home, all while being close to the ground and surrounded by serene nature. It's the perfect place for our family to relax and enjoy life to the fullest.",
        name: "Mr. and Mrs. Siddharth Chaudhary"
    },
]